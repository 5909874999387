<div class="acct-operation-panel">
	<h5 class="acct-operation-panel__title text-uppercase">TRIGGERS</h5>

	<ng-container *ngIf="accountingOperation?.triggers?.length > 0; else noTriggers">
		<ng-container *ngFor="let trigger of accountingOperation.triggers; let first = first">
			<strong *ngIf="!first" class="d-block py-2 px-1">AND</strong>
			<rl-accounting-operation-trigger [anchorTemplateId]="anchorTemplateId" [trigger]="trigger">
			</rl-accounting-operation-trigger>
		</ng-container>
	</ng-container>

	<h5 class="acct-operation-panel__title text-uppercase mt-4">EVENTS</h5>

	<ng-container *ngIf="accountingOperation?.events?.length > 0; else noEvents">
		<ng-container *ngFor="let event of accountingOperation.events; let first = first">
			<strong *ngIf="!first" class="d-block p-2 pt-1">OR</strong>
			<rl-account-operation-event [anchorTemplateId]="anchorTemplateId" [event]="event" [hasCostAllocation]="hasCostAllocation">
			</rl-account-operation-event>
		</ng-container>
	</ng-container>

	<ng-container *rlFeatureEnabled="'accProPhase2'">
		<h5 class="acct-operation-panel__title text-uppercase mt-4">DATE POPULATION</h5>

		<ng-container *ngIf="accountingOperation?.dates?.length > 0; else noDates">
			<ng-container *ngFor="let date of accountingOperation.dates; let first = first">
				<strong *ngIf="!first" class="d-block p-2 pt-1">AND</strong>
				<rl-accounting-operation-date [anchorTemplateId]="anchorTemplateId" [date]="date"[datesDictionary]="datesDictionary">
				</rl-accounting-operation-date>
			</ng-container>
		</ng-container>
	</ng-container>
</div>

<ng-template #noTriggers>
	<div class="rounded d-flex align-items-center p-3 alert alert-warning">
		<div class="text-center">No Triggers Configured.</div>
	</div>
</ng-template>

<ng-template #noEvents>
	<div class="rounded d-flex align-items-center p-3 alert alert-warning">
		<div class="text-center">No Events Configured.</div>
	</div>
</ng-template>

<ng-template #noDates>
	<div class="rounded d-flex align-items-center p-3 alert alert-warning">
		<div class="text-center">No Dates Configured.</div>
	</div>
</ng-template>