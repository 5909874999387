<div class="modal-header">
	<h2 *ngIf="isNewAccountingProcess" class="modal-title">
		Create New Accounting Process for <strong>{{selectedContact.title}}</strong>
	</h2>
	<h2 *ngIf="!isNewAccountingProcess" class="modal-title">
		Manage <strong>{{accountingProcessDetails?.name}}</strong> for <strong>{{selectedContact.title}}</strong>
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close(cancelAccountingProcessPrompt)"
		data-cy="CloseButton">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="add-accounting-process">
	<ng-container [ngSwitch]="currentStep">

		<!-- Accounting Process Step -->
		<ng-container *ngSwitchCase="'accounting-process'">
			<div class="modal-body">
				<div *ngIf="!isLoadingDetails; else accountingProcessLoadingView" [@fadeIn]>
					<div class="add-accounting-process__header p-3" [formGroup]="form">
						<div class="row mb-2">
							<div class="col-2 text-end"><span class="text-danger">*</span> Accounting Process Name:
							</div>
							<div class="col-3"
								[class.has-error]="form.controls.name.invalid && form.controls.name.touched">
								<input type="text" class="form-control add-accounting-process__header-input"
									[formControl]="form.controls.name" placeholder="Add new value"
									data-cy="ProcessNameInput" />
							</div>
						</div>
						<div class="row">
							<div class="col-2 text-end"><span class="text-danger">*</span> Revenue Allocation Template:</div>
							<div class="col-3"
								[class.has-error]="form.controls.anchorTemplateId.invalid && form.controls.anchorTemplateId.touched">
								<select class="form-select add-accounting-process__header-input"
									[formControl]="form.controls.anchorTemplateId" data-cy="AnchorTemplateSelect">
									<option [ngValue]="null">Select</option>
									<option [ngValue]="t.templateID" *ngFor="let t of anchorTemplates">
										{{ t.templateName }}
									</option>
								</select>
							</div>
							<div class="col-6 text-end">
								<button class="btn btn-info btn-sm"
									(click)="saveAccountingProcess(anchorTemplateUpdateWarning)"
									[disabled]="!form?.dirty || !form?.valid || (isSaving$ | async)"
									data-cy="AddProcessButton">
									<span>Add Accounting Process</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="modal-footer">
				<button type="button" class="btn btn-light ms-auto" (click)="close(cancelAccountingProcessPrompt)"
					data-cy="CancelButton">
					Cancel
				</button>
				<button (click)="close(cancelAccountingProcessPrompt)" class="btn btn-success"
					[disabled]="!form?.valid || (isSaving$ | async) || form.dirty" data-cy="DoneButton">
					Done
				</button>
			</div>
		</ng-container>

		<!-- Accounting Operations Step -->
		<ng-container *ngSwitchCase="'accounting-operations'">
			<div class="modal-body">
				<div *ngIf="!isLoadingDetails; else accountingProcessLoadingView" [@fadeIn]>
					<div class="add-accounting-process__header p-3">
						<div class="row mb-2">
							<div class="col-2 text-end"><span class="text-danger">*</span> Accounting Process Name:
							</div>
							<div class="col-3"
								[class.has-error]="form.controls.name.invalid && form.controls.name.touched">
								<input type="text" class="form-control add-accounting-process__header-input"
									[formControl]="form.controls.name" placeholder="Add new value"
									data-cy="AccountingProcessNameInput" />
							</div>
						</div>
						<div class="row">
							<div class="col-2 text-end"><span class="text-danger">*</span> Revenue Allocation Template:</div>
							<div class="col-3"
								[class.has-error]="form.controls.anchorTemplateId.invalid && form.controls.anchorTemplateId.touched">
								<select class="form-select add-accounting-process__header-input"
									[formControl]="form.controls.anchorTemplateId" data-cy="AnchorTemplateSelect">
									<option [ngValue]="null">Select</option>
									<option [ngValue]="t.templateID" *ngFor="let t of anchorTemplates">
										{{ t.templateName }}
									</option>
								</select>
							</div>
							<div class="col-6 text-end">
								<button class="btn btn-info btn-sm"
									(click)="saveAccountingProcess(anchorTemplateUpdateWarning)"
									[disabled]="!form?.valid" [hidden]="!form?.dirty || (isSaving$ | async)"
									data-cy="SaveButton">
									<span>Save</span>
								</button>
							</div>
						</div>
					</div>
					<div class="mt-3 add-accounting-process__scroller">
						<div *ngIf="accountingProcessDetails">
							<rl-accounting-operations [accountingProcess]="accountingProcessDetails" [editMode]="true"
								[accounts]="accounts" [accountGroups]="accountGroups" [openPanelIds]="openOperationPanels"
								[anchorTemplatesDates]="anchorTemplatesDateCmds" [anchorTemplate]="anchorTemplate"
								(onDelete)="operationDeleted($event)">
							</rl-accounting-operations>
						</div>
						<ng-container *ngIf="createOperation || accountingProcessDetails?.operations.length < 1">
							<rl-build-accounting-operation [accountingProcessId]="accountingProcessId"
								[anchorTemplate]="anchorTemplate" [anchorTemplatesDates]="anchorTemplatesDateCmds"
								[accounts]="accounts" [accountGroups]="accountGroups" [@fadeIn] #buildOperation>
							</rl-build-accounting-operation>
							<div class="mt-3 text-right">
								<button class="btn btn-sm btn-info" (click)="addAnotherOperation()"
									[disabled]="!buildOperation.form.valid || !buildOperation.triggersComplete || !buildOperation.eventsComplete"
									data-cy="AddAnotherOperationButton">
									Add Another Operation
								</button>
							</div>
						</ng-container>
						<ng-container *ngIf="!createOperation && accountingProcessDetails?.operations.length > 0">
							<div class="mt-3 text-right">
								<button class="btn btn-sm btn-info" (click)="createOperation = true"
									data-cy="AddAnotherOperationButton2">
									Add Another Operation
								</button>
							</div>
						</ng-container>
					</div>
				</div>
			</div>

			<div class="modal-footer">
				<button type="button" class="btn btn-light ms-auto" (click)="close(cancelAccountingProcessPrompt)"
					data-cy="CancelButton">Cancel</button>
				<button (click)="close(cancelAccountingProcessPrompt)" class="btn btn-success"
					[disabled]="isSaving$ | async" data-cy="DoneButton">
					Done
				</button>
			</div>
		</ng-container>

	</ng-container>
</div>

<ng-template #cancelAccountingProcessPrompt let-prompt="prompt">
	<div>
		<p>There is unsaved work. Do you want to exit this screen?</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm me-2" (click)="prompt.onConfirm.next(false)"
			data-cy="CancelButton">Cancel</button>
		<button class="btn btn-success btn-sm" (click)="prompt.onConfirm.next(true)"
			data-cy="ContinueButton">Continue</button>
	</div>
</ng-template>

<ng-template #accountingProcessLoadingView>
	<div class="add-accounting-process__header p-3" [@fadeIn]>
		<div class="row mb-2">
			<div class="col-2 text-end">
				<div class="shiny__loading__bg" [style.width.px]="170">
					&nbsp;
				</div>
			</div>
			<div class="col-3">
				<div class="shiny__loading__bg" [style.width.%]="100">
					&nbsp;
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-2 text-end">
				<div class="shiny__loading__bg" [style.width.px]="112">
					&nbsp;
				</div>
			</div>
			<div class="col-3">
				<div class="shiny__loading__bg" [style.width.%]="100">
					&nbsp;
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #anchorTemplateUpdateWarning let-prompt="prompt">
	<div>
		<p>Changing the Revenue Allocation Template will clear any configured Triggers, Events, or Date Population Settings. Are you
			sure you
			want to change the Revenue Allocation Template?</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm ms-2" (click)="prompt.onConfirm.next(false)"
			data-cy="cancelButton">Cancel</button>
		<button class="btn btn-success btn-sm" (click)="prompt.onConfirm.next(true)"
			data-cy="ContinueButton">Continue</button>
	</div>
</ng-template>