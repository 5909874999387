<div *ngIf="eventControls.length > 0">
	<div *ngFor="let eventControl of eventControls;let i=index;let first = first">
		<strong *ngIf="!first" class="d-block p-2 pt-1">OR</strong>
		<ng-container *ngIf="editingIndex === i">
			<rl-add-accounting-operation-event [anchorTemplate]="anchorTemplate" [accounts]="accounts"
				[accountGroups]="accountGroups" [event]="eventControl.value" [workflowTrigger]="workflowTrigger" [revenueAllocationTemplateId]="revenueAllocationTemplateId" [costAllocationTemplateId]="costAllocationTemplateId" [hasCostAllocation]="hasCostAllocation"
				(onSave)="updateEvent($event, i)" (onCancel)="cancelEdit(i)">
			</rl-add-accounting-operation-event>
		</ng-container>
		<ng-container *ngIf="editingIndex !== i">
			<rl-account-operation-event [anchorTemplateId]="anchorTemplate?.templateID" [event]="eventControl.value" [hasCostAllocation]="hasCostAllocation">
				<button class="btn btn-icon btn-info btn-sm" (click)="editEvent(i)" [disabled]="editingIndex !== null"
					title="Edit" [attr.data-cy]="'edit' + i">
					<i class="far fa-pencil"></i>
				</button>
				<button class="btn btn-icon btn-remove btn-sm ms-2" (click)="removeEvent(i, confirmDeletePrompt)"
					[disabled]="editingIndex !== null" title="Delete" [attr.data-cy]="'delete' + i">
					<i class="far fa-trash-alt"></i>
				</button>
			</rl-account-operation-event>
		</ng-container>
	</div>
	<div class="d-flex">
		<strong class="p-2">OR</strong>
		<button class="btn btn-sm btn-link" (click)="addEvent(false)" [disabled]="!canAddAnother"
			data-cy="AddAnotherEvent">
			Add Another Event
		</button>
	</div>
</div>
<div *ngIf="eventControls.length === 0">
	<div class="alert alert-warning">
		No Events Added.
	</div>
	<button class="mt-2 btn btn-sm btn-link" (click)="addEvent(false)" data-cy="AddAnEvent">+ Add an Event</button>
</div>

<ng-template #confirmDeletePrompt let-prompt="prompt">
	<div>
		<p>
			Are you sure you want to delete this Event?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="Cancel">
			Cancel
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)" data-cy="Delete">
			Delete
		</button>
	</div>
</ng-template>