@if (!accountingOperations || accountingOperations?.length < 1) { <div
	class="alert alert-warning d-flex align-items-center p-4">
	<div class="text-center">No Accounting Operations Found.</div>
	</div>
	} @else {
	<div [@fadeIn]>
		<div ngbAccordion #accordion="ngbAccordion">
			@for (acctOp of accountingOperations; track acctOp.id) {
			<div ngbAccordionItem="{{ acctOp.id }}" [collapsed]="false" #item="ngbAccordionItem">
				<div ngbAccordionHeader>
					<div class="position-relative accounting-operation">
						<button ngbAccordionButton
							class="accordion-button panel__title d-flex justify-content-between align-items-center"
							[ngClass]="{'collapsed' : !accordion.isExpanded}"
							[attr.data-cy]="'toggleAccordion'+ acctOp.id">
							<span class="cursor-pointer">
								{{acctOp.operationName}}
							</span>
							<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
								[title]="accordion.isExpanded(acctOp.id) ? 'Collapse' : 'Expand'"
								[attr.data-cy]="accordion.isExpanded(acctOp.id) ? 'ToCollapse' : 'ToExpand'"
								[class.open]="accordion.isExpanded(acctOp.id)">
							</i>
						</button>
						<span class="panel__ops position-absolute accounting-operation__panel d-inline-block">
							<span class="d-inline-block ms-3 text-center" data-cy="ExpandCollapseArrow">
								@if (!editMode && !isArchived && !hasContractAdjustments) {
								<span class="accounting-operation__buttons">
									<button class="btn btn-icon py-0 me-2" title="Edit Operation" role="button"
										(click)="editOperation(acctOp)" [attr.data-cy]="'editOperation'+ acctOp.id">
										<i class="far fa-pencil"></i>
									</button>
									<button class="btn btn-icon me-4" title="Delete Operation" role="button"
										(click)="deleteOperation(deleteAccountingOperationPrompt, acctOp)"
										[disabled]="deletingOperations.has(acctOp.id)"
										[attr.data-cy]="'deleteOperation'+ acctOp.id">
										<i class="far fa-trash-alt"></i>
									</button>
								</span>
								}
							</span>
						</span>
					</div>
				</div>
				<div ngbAccordionCollapse>
					<div ngbAccordionBody>
						<ng-template>
							@if (editMode) {
							<rl-build-accounting-operation [accountingProcessId]="accountingProcess.id"
								[operation]="acctOp" [anchorTemplate]="anchorTemplate"
								[anchorTemplatesDates]="anchorTemplatesDates" [accounts]="accounts"
								[accountGroups]="accountGroups" [openPanels]="openPanelIds.includes(acctOp.id)"
								(onSave)="operationSaved($event)">
							</rl-build-accounting-operation>
							} @else {
							<rl-account-operation [accountingOperation]="acctOp"
								[anchorTemplateId]="accountingProcess.anchorTemplateId">
							</rl-account-operation>
							}
						</ng-template>
					</div>
				</div>
			</div>
			}
		</div>
	</div>
	}


	<ng-template #deleteAccountingOperationPrompt let-prompt="prompt">
		<div>
			<p>Are you sure you want to delete the {{ prompt.context.operation.operationName }} Accounting Operation?
			</p>
		</div>
		<div class="text-end mt-3 w-100">
			<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)"
				data-cy="deleteNo">Cancel</button>
			<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)"
				data-cy="deleteYes">Delete</button>
		</div>
	</ng-template>