<div class="account-operation-trigger d-flex justify-content-start align-items-start mb-3">
	<div class="account-operation-trigger__col px-4 d-flex justify-content-start align-items-center">
		<label class="account-operation-trigger__label text-nowrap me-2">
			Trigger Type:
		</label>
		<select class="form-select w-auto" [formControl]="form.controls.triggerType" data-cy="TriggerTypeSelect">
			<option [ngValue]="tuple[0]" *ngFor="let tuple of triggerTypes">{{tuple[1]}}</option>
		</select>
	</div>
	<ng-container [ngSwitch]="form.controls.triggerType.value">

		<!-- Template Association -->
		<ng-container *ngSwitchCase="0">
			<div class="account-operation-trigger__col px-4 border-start">
				<div class="pb-2">
					<em class="d-block my-2 ps-4 ms-3 text-nowrap">
						When a {{ anchorTemplate?.templateName }} or its Parent Installment record is associated to the following Accounting
						Template:
					</em>
				</div>
				<div class="pb-2 d-flex justify-content-start align-items-center">
					<label class="account-operation-trigger__label text-nowrap text-end me-2">
						Template:
					</label>
					<select class="form-select w-auto" [formControl]="form.controls.templateId"
						data-cy="TemplateSelect">
						<option [ngValue]="t.templateID" *ngFor="let t of accountingTemplates">{{ t.templateName }}
						</option>
					</select>
				</div>
			</div>
		</ng-container>

		<!-- Workflow Action -->
		<ng-container *ngSwitchCase="1">
			<div class="account-operation-trigger__col px-4 border-start">
				<div class="pb-2 d-flex justify-content-start align-items-center">
					<label class="account-operation-trigger__label text-nowrap text-end me-2">
						Action:
					</label>
					<select class="form-select me-2 w-auto" [formControl]="form.controls.actionId"
						data-cy="WorkflowActionSelect">
						<option [ngValue]="action.actionId" *ngFor="let action of availableWorkflowActions">
							{{ action.actionName }}
						</option>
					</select>
					<em class="text-nowrap">taken on a {{ anchorTemplate?.templateName }} or its Parent Installment record.</em>
				</div>
				<div class="pb-2 d-flex justify-content-start align-items-center">
					<label class="account-operation-trigger__label text-nowrap text-end me-2">Resulting
						Status:</label>
					<span class="ps-2 text-nowrap">{{ resultingStatus }}</span>
				</div>
			</div>
		</ng-container>

		<!-- Data Behavior-->
		<ng-container *ngSwitchCase="2">
			<div class="account-operation-trigger__col px-4 border-start">
				<div *ngIf="parentTableTemplateOptions?.length > 0"
					class="pb-2 d-flex justify-content-start align-items-start">
					<label class="account-operation-trigger__label text-nowrap text-end me-1 p-1">
						Template:
					</label>
					<select class="form-select me-2 w-auto" [formControl]="templateOptionControl"
						data-cy="TemplateOptionControlSelect">
						<optgroup label="Revenue Allocation Template">
							<option [ngValue]="opt" *ngFor="let opt of anchorTemplateOptions">
								{{ opt.template.templateName }}
							</option>
						</optgroup>
						<optgroup *ngIf="parentTableTemplateOptions.length > 0" label="Parent Table Template">
							<option [ngValue]="opt" *ngFor="let opt of parentTableTemplateOptions">
								{{ opt.template.templateName }}
							</option>
						</optgroup>
					</select>
				</div>
				<div class="pb-2 d-flex justify-content-start align-items-start">
					<label class="account-operation-trigger__label text-nowrap text-end me-1 p-1">
						Field:
					</label>
					<select class="form-select me-2 w-auto" [formControl]="form.controls.characteristicId"
						data-cy="FieldSelect">
						<option [ngValue]="cmd.characteristicID" *ngFor="let cmd of templateCmds">
							{{ cmd.label }}
						</option>
					</select>
					<select class="form-select me-2 w-auto" [formControl]="form.controls.operator"
						data-cy="OperatorsSelect">
						<option [ngValue]="op.operator" *ngFor="let op of availableOperators">
							{{ op.label }}
						</option>
					</select>
					<rl-data-behavior-value-entry [valueEntryType]="valueEntryType" [cmd]="selectedDataBehaviorCmd"
						[form]="form">
					</rl-data-behavior-value-entry>
				</div>
			</div>
		</ng-container>

		<!-- Record Creation -->
		<ng-container *ngSwitchCase="3">
			<div class="account-operation-trigger__col px-4 border-start">
				<div>
					<em class="d-block my-2 ps-4 ms-3 text-nowrap">
						When a
						<select class="form-select w-auto d-inline" [formControl]="form.controls.amountTemplateId"
							data-cy="WhenTemplateSelect">
							<option [ngValue]="t.templateID" *ngFor="let t of amountTemplates">{{ t.templateName }}
							</option>
						</select>
						is created on a
						<select class="form-select w-auto d-inline" [formControl]="form.controls.tableTemplateId"
							data-cy="CreatedOnTemplateSelect">
							<option [ngValue]="t.templateID" *ngFor="let t of tableTemplates">{{ t.templateName }}
							</option>
						</select>
					</em>
				</div>
				<div class="pb-2">
					<em class="d-block my-2 ps-4 ms-3 text-nowrap text-muted">
						Where the same Catalog association exists on the above record creation and existing Anchor
						Template records
					</em>
				</div>
			</div>
		</ng-container>

	</ng-container>
</div>
<div class="align-items-center justify-content-end d-flex mt-4">
	<span *ngIf="form.hasError('duplicateTriggers')" class="text-danger text-nowrap me-4">
		Duplicate triggers are not allowed
	</span>
	<span *ngIf="form.hasError('duplicateWorkflow')" class="text-danger text-nowrap me-4">
		Only one trigger can be of type Workflow Action
	</span>
	<button class="btn btn-light btn-sm me-2" (click)="cancel()" data-cy="CancelForm">
		Cancel
	</button>
	<button class="btn btn-info btn-sm" (click)="save()" [disabled]="!form.valid"
		data-cy="SubmitForm">
		Save
	</button>
</div>