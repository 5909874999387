import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbAccordionToggle, NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { isEmpty, some } from "lodash";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { AccountingProcessesService } from "rl-common/services/accounting-processes/accounting-processes.service";
import { DateGroupType } from "rl-common/services/accounting-processes/models/date-group-type";
import { IAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-accounting-process-account";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { ICreateAccountingOperation } from "rl-common/services/accounting-processes/models/i-create-accounting-operation";
import { ICreateAccountingOperationEventAccountOrGroupMapping } from "rl-common/services/accounting-processes/models/i-create-accounting-operation-event-account-or-group-mapping";
import { ICreateDate } from "rl-common/services/accounting-processes/models/i-create-date";
import { ICreateEvent } from "rl-common/services/accounting-processes/models/i-create-event";
import { ICreateTrigger } from "rl-common/services/accounting-processes/models/i-create-trigger";
import { TriggerEventType } from "rl-common/services/accounting-processes/models/trigger-event-type";
import { Subscription } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { FaIconDirective } from "../../../../common/directives/fa-icon.directive";
import { FeatureEnabledDirective } from "../../../../common/directives/feature-enabled.directive";
import { IAccountingOperationForm } from "../accounting-processes.models";
import { AddAccountingOperationDatesComponent } from "../add-accounting-operation-dates/add-accounting-operation-dates.component";
import { AddAccountingOperationEventsComponent } from "../add-accounting-operation-events/add-accounting-operation-events.component";
import { AddAccountingOperationTriggersComponent } from "../add-accounting-operation-triggers/add-accounting-operation-triggers.component";
import { BuildAccountingProcessService } from "../build-accounting-process-modal/build-accounting-process.service";
import { IBuildAccountingOperationAccountOrGroup } from "./models/i-build-accounting-operation-account-or-group";
import { IBuildAccountingOperationDate } from "./models/i-build-accounting-operation-date";
import { IBuildAccountingOperationEvent } from "./models/i-build-accounting-operation-event";
import { IBuildAccountingOperationTrigger } from "./models/i-build-accounting-operation-trigger";

@Component({
	selector: "rl-build-accounting-operation",
	templateUrl: "./build-accounting-operation.component.html",
	styleUrls: ["./build-accounting-operation.component.scss"],
	imports: [ReactiveFormsModule, NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgClass, NgIf, FaIconDirective, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, AddAccountingOperationTriggersComponent, AddAccountingOperationEventsComponent, FeatureEnabledDirective, AddAccountingOperationDatesComponent, AsyncPipe]
})
export class BuildAccountingOperationComponent implements OnInit, OnChanges, OnDestroy {

	@Input()
	accountingProcessId: string;

	@Input()
	operation: ICreateAccountingOperation;

	@Input()
	anchorTemplate: ICharacteristicTemplate;

	@Input()
	anchorTemplatesDates: { [key: number]: ICharacteristicMetaData[] } = {};

	@Input()
	accounts: IAccountingProcessAccount[] = [];

	@Input()
	accountGroups: IAccountingProcessAccountGroup[] = [];

	@Input()
	openPanels = false;

	@Output()
	anchorTemplateChange = new EventEmitter<ICharacteristicTemplate>();

	@Output()
	onSave = new EventEmitter<ICreateAccountingOperation>();

	@ViewChild("acc")
	accordion: NgbAccordionDirective;

	isNew = true;
	isSaved = false;
	workflowTrigger: IBuildAccountingOperationTrigger = null;
	operationTriggers: ICreateTrigger[] = [];

	form: FormGroup<IAccountingOperationForm>;
	formKey: string;

	initialPanelIds: string[] = ["add-triggers"];

	get triggersComplete() {
		const operationSaved = this.form.controls.id.value;
		const controls = this.form.controls.triggers.controls;
		const hasSavedTriggers = some(controls, control => !!control.value.id);
		return operationSaved && hasSavedTriggers;
	}

	get eventsComplete() {
		const operationSaved = this.form.controls.id.value;
		const controls = this.form.controls.events.controls;
		const hasSavedEvents = some(controls, control => !!control.value.id);
		return operationSaved && hasSavedEvents;
	}

	get datesComplete() {
		const operationSaved = this.form.controls.id.value;
		const controls = this.form.controls.dates.controls;
		const hasSavedDates = some(controls, control => !!control.value.accountingProcessOperationDateId && control.value.dateGroupTypeId === DateGroupType.FirstRecord);
		return operationSaved && hasSavedDates;
	}

	isSaving$ = this._buildAccountingProcessService.status$.pipe(map(x => x.isSaving));

	private _formSubs: Subscription[] = [];
	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _formBuilder: FormBuilder,
		private readonly _accountingProcessesService: AccountingProcessesService,
		private readonly _buildAccountingProcessService: BuildAccountingProcessService
	) { }

	ngOnInit(): void {
		this.isNew = !this.operation;
		if (!this.isNew) {
			if (this.openPanels) {
				this.initialPanelIds = ["add-triggers", "add-events"];
			} else {
				this.initialPanelIds = [];
			}
		}
		const addAnotherOpSub = this._buildAccountingProcessService.addAnotherOperation$.subscribe(() => {
			this.reset();
		});
		this.operationTriggers = this.operation?.triggers;
		this.buildForm(this.operation);
		this._subs.push(addAnotherOpSub);
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.anchorTemplate) {
			this.onAnchorTemplateChange(changes.anchorTemplate.currentValue);
		}

		if (changes.operation) {
			this.buildForm(this.operation);
		}
	}

	onAnchorTemplateChange(template: ICharacteristicTemplate) {
		this.anchorTemplate = template;
		this.anchorTemplateChange.emit(template);
	}

	buildForm(operation: ICreateAccountingOperation = null) {
		this._formSubs.forEach(sub => sub.unsubscribe());

		if (this.formKey) {
			this._buildAccountingProcessService.unregisterForm(this.formKey);
		}

		this.form = this._formBuilder.group({
			id: new FormControl<string>(operation?.id),
			name: new FormControl<string>(operation?.operationName, [Validators.required]),
			triggers: new FormArray<FormControl<IBuildAccountingOperationTrigger>>(this.mapTriggers(operation?.triggers)),
			events: new FormArray<FormControl<IBuildAccountingOperationEvent>>(this.mapEvents(operation?.events)),
			dates: new FormArray<FormControl<IBuildAccountingOperationDate>>(this.mapDates(operation?.dates))
		});

		this.workflowTrigger = this.form.controls.triggers.controls.find(control => control.value.triggerType === TriggerEventType.WorkflowAction)?.value;

		const triggersChangedSub = this.form.controls.triggers.valueChanges.subscribe((triggers) => {
			this.saveAccountingOperation();
			this.workflowTrigger = triggers.find(x => x.triggerType === TriggerEventType.WorkflowAction);
			this.operationTriggers = this.mapOperationTriggers(triggers);
		});

		const eventsChangedSub = this.form.controls.events.valueChanges.subscribe(() => {
			this.saveAccountingOperation();
		});

		const datesChangedSub = this.form.controls.dates.valueChanges.subscribe(() => {
			this.saveAccountingOperation();
		});

		this.formKey = `BuildAccountingOperationComponent-${operation?.id ?? "0"}`;
		this._buildAccountingProcessService.registerForm(this.formKey, this.form);

		this._formSubs = [triggersChangedSub, eventsChangedSub, datesChangedSub];
	}

	private mapTriggers(triggers: ICreateTrigger[]): FormControl<IBuildAccountingOperationTrigger>[] {
		if (!triggers) {
			return [];
		}
		return triggers.map(x => {
			const trigger = {
				id: x.id,
				triggerType: x.triggerType,
				actionId: x.actionId,
				charTypeId: x.charTypeId,
				templateId: x.templateId,
				characteristicId: x.characteristicId,
				operator: x.operator,
				value: x.value,
				rangeValue1: x.rangeValue1,
				rangeValue2: x.rangeValue2
			} as IBuildAccountingOperationTrigger;
			return new FormControl<IBuildAccountingOperationTrigger>(trigger);
		});
	}

	private mapOperationTriggers(triggers: IBuildAccountingOperationTrigger[]) {
		if (!triggers) {
			return [];
		}
		return triggers.map(x => {
			return {
				id: x.id,
				triggerType: x.triggerType,
				actionId: x.actionId,
				charTypeId: x.charTypeId,
				templateId: x.templateId,
				characteristicId: x.characteristicId,
				operator: x.operator,
				value: x.value,
				rangeValue1: x.rangeValue1,
				rangeValue2: x.rangeValue2
			} as ICreateTrigger;
		});
	}

	private mapEvents(events: ICreateEvent[]): FormControl<IBuildAccountingOperationEvent>[] {
		if (!events) {
			return [];
		}
		return events.map(x => {
			const accounts = x.accountOrGroups.map(account => {
				const a: IBuildAccountingOperationAccountOrGroup = {
					debitId: account.debitId,
					creditId: account.creditId,
					debitName: account.debitName,
					creditName: account.creditName,
					allocationType: account.allocationType
				} as IBuildAccountingOperationAccountOrGroup;
				return a;
			});
			const event = {
				id: x.id,
				statusId: x.statusId,
				workflowActionId: x.workflowActionId,
				accountOrGroups: accounts
			} as IBuildAccountingOperationEvent;
			return new FormControl<IBuildAccountingOperationEvent>(event);
		});
	}

	private mapDates(dates: ICreateDate[]): FormControl<IBuildAccountingOperationDate>[] {
		if (!dates) {
			return [];
		}
		return dates.map(x => {
			const date = {
				accountingProcessOperationDateId: x.accountingProcessOperationDateId,
				dateGroupTypeId: x.dateGroupTypeId,
				dateTypeId: x.dateTypeId,
				characteristicId: x.characteristicId,
				characteristicIdDefault1: x.characteristicIdDefault1,
				populateTypeId: x.populateTypeId
			} as IBuildAccountingOperationDate
			return new FormControl<IBuildAccountingOperationDate>(date);
		});
	}

	saveAccountingOperation() {
		if (!this.form.valid || this._buildAccountingProcessService.status$.value.isSaving) {
			return;
		}
		this._buildAccountingProcessService.emitStatus({ isSaving: true, message: `Saving ...` });
		const triggers = this.form.controls.triggers.controls
			.filter(control => !isEmpty(control.value))
			.map(triggerControl => {
				const trigger = triggerControl.value;
				const createTrigger: ICreateTrigger = {
					id: trigger.id,
					accountProcessOperationId: this.form.controls.id.value,
					triggerType: trigger.triggerType,
					actionId: trigger.actionId,
					charTypeId: trigger.charTypeId,
					templateId: trigger.templateId,
					characteristicId: trigger.characteristicId,
					operator: trigger.operator,
					value: trigger.value,
					rangeValue1: trigger.rangeValue1,
					rangeValue2: trigger.rangeValue2,
					amountTemplateId: trigger.amountTemplateId,
					tableTemplateId: trigger.tableTemplateId
				};
				return createTrigger;
			});
		const events = this.form.controls.events.controls
			.filter(control => !isEmpty(control.value))
			.map(eventControl => {
				const event = eventControl.value;
				const accountOrGroups = (event.accountOrGroups ?? []).map(x => ({
					debitId: x.debitId,
					creditId: x.creditId,
					debitName: x.debitName,
					creditName: x.creditName,
					allocationType: x.allocationType
				} as ICreateAccountingOperationEventAccountOrGroupMapping));
				const createEvent: ICreateEvent = {
					id: event.id,
					accountProcessOperationId: this.form.controls.id.value,
					statusId: event.statusId,
					workflowActionId: event.workflowActionId,
					accountOrGroups
				};
				return createEvent;
			});
		const dates = this.form.controls.dates.controls
			.filter(control => !isEmpty(control.value))
			.map(dateControl => {
				const date = dateControl.value;
				const createDate: ICreateDate = {
					accountingProcessOperationDateId: date.accountingProcessOperationDateId,
					accountProcessOperationId: this.form.controls.id.value,
					dateGroupTypeId: date.dateGroupTypeId,
					dateTypeId: date.dateTypeId,
					characteristicId: date.characteristicId,
					characteristicIdDefault1: date.characteristicIdDefault1,
					populateTypeId: date.populateTypeId
				};
				return createDate;
			})
		const operation: ICreateAccountingOperation = {
			accountingProcessId: this.accountingProcessId,
			id: this.form.controls.id.value,
			operationName: this.form.controls.name.value,
			triggers,
			events,
			dates
		};
		const sub = this._accountingProcessesService.upsertAccountingOperation(operation).pipe(
			finalize(() => {
				this._buildAccountingProcessService.emitStatus({ isSaving: false, message: `Saved` });
			})
		).subscribe((result) => {
			this.buildForm(result);
			this.onSave.emit(operation);
			this.isSaved = true;
		});
		this._subs.push(sub);
	}

	reset() {
		this.operation = null;
		this.buildForm();
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
		this._formSubs.forEach(sub => sub.unsubscribe());
		if (this.formKey) {
			this._buildAccountingProcessService.unregisterForm(this.formKey);
		}
	}
}
