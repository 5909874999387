<div class="acct-operation-event container-fluid mb-2">
	<div class="row align-items-stretch">
		<div class="col-4 bg-light py-3">
			<h5 class="ps-3 mt-1">Workflow <em>(optional)</em></h5>
			<div class="pb-3 form-switch ms-3">
				<input type="checkbox" [formControl]="form.controls.workflowEnabled" class="form-check-input"
					data-cy="DoNotApplyWorkflow" />
				<label class="form-check-label ps-2">
					Apply Workflow to the template(s): <strong>
						@if(costAllocationTemplate) {
							{{getAnchorAndCostAllocationTemplateText()}}
						}
						@else {
							{{getAnchorTemplateText()}}
						}
					</strong>
				</label>
			</div>
			@if(!!form.controls.workflowEnabled.value) {
			<div class="d-flex flex-column">
				<div class="acct-operation-event__item purple-arrow">
					<strong class="d-block mb-2">Initial Status</strong>
					<select class="form-select me-2" [formControl]="form.controls.statusId" data-cy="SelectStatus">
						<option [ngValue]="status.stepId" *ngFor="let status of availableStatuses">
							{{ status.stepName }}
						</option>
					</select>
				</div>
				<div class="acct-operation-event__item purple-arrow">
					<strong class="d-block mb-2">Workflow Action</strong>
					<select class="form-select me-2" [formControl]="form.controls.workflowActionId"
						data-cy="SelectWorkflowAction">
						<option [ngValue]="action.actionId" *ngFor="let action of availableWfActions">
							{{ action.actionName }}
						</option>
					</select>
				</div>
				<div class="acct-operation-event__item">
					<strong class="d-block mb-2">Resulting Status</strong>
					<em class="d-block">{{ this.resultingStatus.stepName }}</em>
				</div>
			</div>
			}
		</div>
		<div class="col-8 p-3">
			<div>
				<h5 class="mt-1">Revenue Allocation</h5>
				<p>Select at least one Debit Account and one Credit Account for the Revenue Allocation Template: <strong>{{getAnchorTemplateText()}}</strong></p>
				<div class="row align-items-center pt-11px">
					<strong class="col-5">Debit Account(s)</strong>
					<strong class="col-5">Credit Account(s)</strong>
				</div>
				<div class="row align-items-center acct-operation-event__accounts-row py-1"
					*ngFor="let control of form.controls.revAccountOrGroups.controls; let i = index"
					[class.has-error]="form.hasError('revAccountUniqueness')">
					<div class="col-5">
						<select class="form-select me-2" [(ngModel)]="control.value.debitId"
							[compareWith]="compareAccountOrGroup" (ngModelChange)="updateAccountOrGroupControl(i, AllocationType.Revenue)"
							[attr.data-cy]="'DebitAccountOrGroup' + i">
							<optgroup label="Accounts">
								<option [ngValue]="{ id: account.id, isGroup: false}"
									*ngFor="let account of availableDebitAccounts(control)">
									{{ account.name }}
								</option>
							</optgroup>
							<optgroup label="Account Groups">
								<option [ngValue]="{ id: accountGroup.id, isGroup: true }"
									*ngFor="let accountGroup of availableDebitAccountGroups(control)">
									{{ accountGroup.groupName }}
								</option>
							</optgroup>
						</select>
					</div>
					<div class="col-5">
						<select class="form-select me-2" [(ngModel)]="control.value.creditId"
							[compareWith]="compareAccountOrGroup" (ngModelChange)="updateAccountOrGroupControl(i, AllocationType.Revenue)"
							[attr.data-cy]="'CreditAccountOrGroup' + i">
							<optgroup label="Accounts">
								<option [ngValue]="{ id: account.id, isGroup: false }"
									*ngFor="let account of availableCreditAccounts(control)">
									{{ account.name }}
								</option>
							</optgroup>
							<optgroup label="Account Groups">
								<option [ngValue]="{ id: accountGroup.id, isGroup: true }"
									*ngFor="let accountGroup of availableCreditAccountGroups(control)">
									{{ accountGroup.groupName }}
								</option>
							</optgroup>
						</select>
					</div>
					<div *ngIf="form.controls.revAccountOrGroups.controls.length > 1"
						class="col-2 acct-operation-event__accounts-buttons align-items-center justify-content-end pe-2">
						<button class="btn btn-sm btn-icon btn-remove" (click)="removeAccount(i, AllocationType.Revenue)" title="Delete"
							data-cy="Delete">
							<i class="far fa-trash-alt"></i>
						</button>
					</div>
				</div>
				<div *ngIf="form.hasError('revAccountUniqueness')" class="text-danger">
					Account or Account Groups must be unique.
				</div>
				<div *ngIf="form.hasError('revAccountsRequired')" class="text-danger">
					At least one credit/debit account combination is required.
					<button class="btn btn-sm btn-link" (click)="addAnotherAccountOrGroup(AllocationType.Revenue)" data-cy="AddRow">+ Add
						Row</button>
				</div>
				<div *ngIf="form.controls.revAccountOrGroups.controls?.length > 0" class="py-1">
					<button class="btn btn-sm btn-link" (click)="addAnotherAccountOrGroup(AllocationType.Revenue)" data-cy="AddAnotherRow">+
						Add Another Row</button>
				</div>
			</div>
			@if (hasCostAllocation) {
			<div class="border-top pt-2 mt-1">
				<h5 class="mt-1">Cost Allocation</h5>
				<p>Select at least one Debit Account and one Credit Account for the Cost Allocation Template: <strong>{{getCostAllocationTemplateText()}}</strong></p>
				<div class="row align-items-center pt-11px">
					<strong class="col-5">Debit Account(s)</strong>
					<strong class="col-5">Credit Account(s)</strong>
				</div>
				<div class="row align-items-center acct-operation-event__accounts-row py-1"
					*ngFor="let control of form.controls.costAccountOrGroups.controls; let i = index"
					[class.has-error]="form.hasError('costAccountUniqueness')">
					<div class="col-5">
						<select class="form-select me-2" [(ngModel)]="control.value.debitId" [compareWith]="compareAccountOrGroup"
							(ngModelChange)="updateAccountOrGroupControl(i, AllocationType.Cost)" [attr.data-cy]="'DebitAccountOrGroup' + i">
							<optgroup label="Accounts">
								<option [ngValue]="{ id: account.id, isGroup: false}"
									*ngFor="let account of availableDebitAccounts(control)">
									{{ account.name }}
								</option>
							</optgroup>
							<optgroup label="Account Groups">
								<option [ngValue]="{ id: accountGroup.id, isGroup: true }"
									*ngFor="let accountGroup of availableDebitAccountGroups(control)">
									{{ accountGroup.groupName }}
								</option>
							</optgroup>
						</select>
					</div>
					<div class="col-5">
						<select class="form-select me-2" [(ngModel)]="control.value.creditId" [compareWith]="compareAccountOrGroup"
							(ngModelChange)="updateAccountOrGroupControl(i, AllocationType.Cost)" [attr.data-cy]="'CreditAccountOrGroup' + i">
							<optgroup label="Accounts">
								<option [ngValue]="{ id: account.id, isGroup: false }"
									*ngFor="let account of availableCreditAccounts(control)">
									{{ account.name }}
								</option>
							</optgroup>
							<optgroup label="Account Groups">
								<option [ngValue]="{ id: accountGroup.id, isGroup: true }"
									*ngFor="let accountGroup of availableCreditAccountGroups(control)">
									{{ accountGroup.groupName }}
								</option>
							</optgroup>
						</select>
					</div>
					<div *ngIf="form.controls.costAccountOrGroups.controls.length > 1"
						class="col-2 acct-operation-event__accounts-buttons align-items-center justify-content-end pe-2">
						<button class="btn btn-sm btn-icon btn-remove" (click)="removeAccount(i, AllocationType.Cost)" title="Delete" data-cy="Delete">
							<i class="far fa-trash-alt"></i>
						</button>
					</div>
				</div>
				<div *ngIf="form.hasError('costAccountUniqueness')" class="text-danger">
					Account or Account Groups must be unique.
				</div>
				<div *ngIf="form.hasError('costAccountsRequired')" class="text-danger">
					At least one credit/debit account combination is required.
					<button class="btn btn-sm btn-link" (click)="addAnotherAccountOrGroup(AllocationType.Cost)" data-cy="AddRow">+ Add
						Row</button>
				</div>
				<div *ngIf="form.controls.costAccountOrGroups.controls?.length > 0" class="py-1">
					<button class="btn btn-sm btn-link" (click)="addAnotherAccountOrGroup(AllocationType.Cost)" data-cy="AddAnotherRow">+
						Add Another Row</button>
				</div>
			</div>
			}
		</div>
	</div>
</div>
<div class="d-flex mb-2">
	<div class="ms-auto">
		<button class="btn btn-light btn-sm me-2" (click)="cancel()" data-cy="CancelForm">
			Cancel
		</button>
		<button class="btn btn-info btn-sm" (click)="save()" [disabled]="!form.valid" data-cy="SaveForm">
			Save
		</button>
	</div>
</div>