<ng-container>
	@switch (true) {
	@case (isEditingExistingAccount) {
	<h3 class="mt-4">Update Account</h3>
	}
	@case (isEditingExistingAccountGroup) {
	<h3 class="mt-4">Update Account Group</h3>
	}
	@default {
	<h3 class="mt-3 d-inline-flex align-items-center">
		Add
		<select class="form-select w-auto mt-1 ms-2 text-larger" [(ngModel)]="formType"
			(ngModelChange)="coaOptionChanged()">
			<option *ngFor="let type of coaOptions" [ngValue]="type.id">{{type.value}}</option>
		</select>
	</h3>
	}
	}
</ng-container>

@if ( formType === 'account' ) {
<div class="g-0 p-3 mb-4 mt-2 add-panel" [ngClass]="formBorderClass">
	<div class="row align-items-start">
		<div class="col-xl col-lg-6 pe-3"
			[class.has-error]="accountsForm.controls.name.invalid && (accountsForm.controls.name.dirty || accountsForm.controls.name.touched)">
			<label class="form-label fw-bold">Account Name</label>
			<input class="form-control" type="text" placeholder="Enter" [formControl]="accountsForm.controls.name"
				data-cy="accountName" />
			<div *ngIf="accountsForm.controls.name.errors?.accountNameDuplicate" class="help-block d-inline-block p-1"
				data-cy="accountNameDuplicate">
				Duplicate Account Name is not allowed.</div>
		</div>
		<div class="col-xl col-lg-6 pe-3">
			<label class="form-label fw-bold">Account ID</label>
			<input *ngIf="isEditingExistingAccount" class="form-control" type="text" placeholder="Enter"
				[ngModel]="accountsForm.controls.accountId.value" [disabled]="true" data-cy="accountId" />
			<input *ngIf="!isEditingExistingAccount" class="form-control" type="text" placeholder="Enter"
				[formControl]="accountsForm.controls.accountId" data-cy="accountId" />
		</div>
		<div *ngIf="isRlAdmin" class="col-xl col-lg-6 pe-3">
			<label class="form-label fw-bold text-nowrap">XREF <span
					class="text-muted fw-normal ms-2">(optional)</span></label>
			<input class="form-control" type="text" placeholder="Enter" [formControl]="accountsForm.controls.xref"
				data-cy="xref" />
		</div>
		<div class="col-xl col-lg-6 pe-xl">
			<label class="form-label fw-bold text-nowrap">Account Notes <span
					class="text-muted fw-normal ms-2">(optional)</span></label>
			<input class="form-control" type="text" placeholder="Enter" [formControl]="accountsForm.controls.notes"
				data-cy="notes" />
		</div>
	</div>
	<div class="row">
		<div class="col-xl col-lg-6 mt-4"
			[class.text-grey]="isThereAnFxGainLossAccountAlready() && !arePostingsGenerated">
			@if(arePostingsGenerated) {
			<span class="p-1"><i class="fa fa-check text-success"></i></span>
			} @else {
			<rl-checkbox-input [formControl]="accountsForm.controls.fxGainLossInd" [dataLabel]="'FxGainLoss'"
				[disabled]="isSubmitting || isThereAnFxGainLossAccountAlready()">
			</rl-checkbox-input>
			}
			Tag as the FX Gain/Loss account
			<span *ngIf="isThereAnFxGainLossAccountAlready() && !arePostingsGenerated">(an account has already
				been tagged)</span>
			<em *ngIf="arePostingsGenerated" class="text-grey">(postings generated; cannot be changed)</em>
		</div>
		<div class="col-xl col-lg-6 mt-3 text-end ms-auto">
			<button class="btn btn-light" type="button" (click)="cancelAccount(cancelPrompt)"
				[disabled]="isSubmitting || !accountsForm.dirty && !isEditingExistingAccount || isFormEmpty(accountsForm)"
				data-cy="cancelButton">
				<i class="far fa-times"></i>
			</button>
			<button class="btn btn-success ms-2" [class.btn-edit]="isEditingExistingAccount" type="button"
				(click)="saveAccount()" [disabled]="!accountsForm.dirty || !accountsForm.valid || isSubmitting"
				data-cy="addUpdateButton" [attr.aria-label]="isEditingExistingAccount ? 'Update' : 'Add'">
				<i *ngIf="isSubmitting" class="far fa-spinner fa-spin me-1"></i>
				<i class="far fa-check"></i>
			</button>
		</div>
	</div>
</div>
} @else {
<div class="row g-0 align-items-start p-3 mt-2 mb-4 add-panel" [ngClass]="formBorderClass">
	<div class="col-xl col-lg-6 pe-3"
		[class.has-error]="accountGroupsForm.controls.groupName.invalid && (accountGroupsForm.controls.groupName.dirty || accountGroupsForm.controls.groupName.touched)">
		<label class="form-label fw-bold">Account Group Name</label>
		<input class="form-control" type="text" placeholder="Enter" [formControl]="accountGroupsForm.controls.groupName"
			data-cy="accountGroupName" />
		<div *ngIf="accountGroupsForm.controls.groupName.errors?.accountGroupNameDuplicate"
			class="help-block d-inline-block p-1" data-cy="accountGroupNameDuplicate">
			Duplicate Account Group Name is not allowed.</div>
	</div>
	<div class="col-xl col-lg-6 pe-3">
		<label class="form-label fw-bold">Deal Template</label>
		<select [formControl]="accountGroupsForm.controls.dealTemplateId" class="form-select"
			data-cy="accountGroupDealTemplate">
			<option [ngValue]="null" *ngIf="allDealTemplates.length === 0" disabled>
				No Deal Templates Found
			</option>
			<option [ngValue]="null" *ngIf="allDealTemplates.length > 0" disabled>
				Select Deal Template
			</option>
			<option *ngFor="let template of allDealTemplates" [ngValue]="template.templateID">{{template.templateID + "
				- " + template.templateName}}</option>
		</select>
	</div>
	<div class="col-xl col-lg-6 pe-3">
		<label class="form-label fw-bold">Party</label>
		<select [formControl]="accountGroupsForm.controls.partyId" class="form-select" data-cy="accountGroupParty">
			<option *ngIf="selectedDealPartyCollection.length === 0 && accountGroupsForm.controls.dealTemplateId.dirty"
				[ngValue]="null" disabled>
				No Applicable Parties Found
			</option>
			<option [ngValue]="null" *ngIf="selectedDealPartyCollection.length > 0">
				Select Party
			</option>
			<option *ngFor="let party of selectedDealPartyCollection" [ngValue]="party.partyID">{{party.partyID + " - "
				+ party.partyName}}</option>
		</select>
	</div>
	<div class="col-xl col-lg-6 pe-3">
		<label class="form-label fw-bold">Default Account</label>
		<select [formControl]="accountGroupsForm.controls.defaultAccountId" class="form-select"
			data-cy="accountGroupDefaultAccount">
			<option [ngValue]="null" *ngIf="defaultAccounts.length === 0" disabled>
				No Accounts Found
			</option>
			<option [ngValue]="null" *ngIf="defaultAccounts.length > 0" disabled>
				Select Account
			</option>
			<option *ngFor="let defaultAccount of defaultAccounts" [ngValue]="defaultAccount[0]">{{defaultAccount[1]}}
			</option>
		</select>
	</div>
	<div class="col-xl col-lg-12 mt-4 text-end text-nowrap">
		<button class="btn btn-light ms-3" type="button" (click)="cancelGroup(cancelGroupPrompt)"
			[disabled]="isSubmitting || !accountGroupsForm.dirty && !isEditingExistingAccountGroup || isFormEmpty(accountGroupsForm)"
			data-cy="cancelGroupButton">
			<i class="far fa-times"></i>
		</button>
		<button class="btn btn-success ms-2" [class.btn-edit]="isEditingExistingAccountGroup" type="button"
			(click)="saveAccountGroup()"
			[disabled]="!accountGroupsForm.dirty || !accountGroupsForm.valid || isSubmitting"
			data-cy="addUpdateGroupButton">
			<i *ngIf="isSubmitting" class="far fa-spinner fa-spin me-1"></i>
			<i class="far fa-check"></i>
		</button>
	</div>
</div>
}

<ul ngbNav #nav="ngbNav" class="nav-tabs tabset--rl" [destroyOnHide]="false" [(activeId)]="activeTabId">
	<li [ngbNavItem]="coaFormTypesIndex.Account">
		<a ngbNavLink data-cy="AccountsTab" (click)="coaTabChanged()">Accounts</a>
		<ng-template ngbNavContent>
			@if (accountsDataSource.indexedRowData$?.value?.length > 0) {
			<rl-grid [options]="gridOptions" [dataSource]="accountsDataSource">
				<div *rlCellTemplate="'fxGainLossInd'; let cellData" class="text-center">
					<i *ngIf="cellData.account.fxGainLossInd" class="fas fa-check"></i>
				</div>
				<ng-container *rlGridRowHoverOps="let record='rowData'">
					<ng-container *ngIf="record.account.activeIndicator === activeIndicator">
						<button *rlIsRlAdmin class="btn btn-icon btn-light" (click)="syncAccount(record.account)"
							title="Sync Account" data-cy="SyncAccountButton">
							<i class="far fa-sync-alt"></i>
						</button>
						<button class="btn btn-icon btn-light" (click)="editAccount(record.account, cancelPrompt)"
							title="Edit Account" data-cy="EditButton">
							<i class="far fa-pencil"></i>
						</button>
						<button *ngIf="!record.account.alliantAccountGuid" class="btn btn-icon btn-light"
							(click)="deleteAccount(record, confirmDeletePrompt)" [disabled]="isDeleting.has(record.id)"
							title="Delete Account" data-cy="DeleteButton">
							<i class="far fa-trash-alt"></i>
						</button>
						<button *ngIf="record.account.alliantAccountGuid" class="btn btn-icon btn-light"
							(click)="archiveAccount(record, confirmArchivePrompt)"
							[disabled]="isDeleting.has(record.id)" title="Archive Account" data-cy="ArchiveButton">
							<i class="far fa-archive"></i>
						</button>
					</ng-container>
				</ng-container>
			</rl-grid>
			} @else {
			<div>
				<span>No Accounts</span>
			</div>
			}
		</ng-template>
	</li>
	<li [ngbNavItem]="coaFormTypesIndex.AccountGroup">
		<a ngbNavLink data-cy="AccountGroupsTab" (click)="coaTabChanged()">Account Groups</a>
		<ng-template ngbNavContent>
			@if (sortedAccountGroups.length > 0) {
			<div ngbAccordion #accordion="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false">
				@for (group of sortedAccountGroups; track group) {
				<div ngbAccordionItem="{{ group.id }}" #item="ngbAccordionItem">
					<div ngbAccordionHeader class="accordion-header position-relative">
						<button ngbAccordionButton
							class="accordion-button panel__title d-flex justify-content-between align-items-center"
							[ngClass]="{'collapsed' : !accordion.isExpanded}"
							[attr.data-cy]="'toggleAccordion' + group.groupName | trimWhitespace">
							<span class="cursor-pointer">
								{{ group.groupName }}
							</span>
							<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
								[title]="accordion.isExpanded(group.id) ? 'Collapse' : 'Expand'"
								[attr.data-cy]="accordion.isExpanded(group.id) ? 'ToCollapse' : 'ToExpand'"
								[class.open]="accordion.isExpanded(group.id)">
							</i>
						</button>
						<div class="align-items-center panel__ops accordion-expanded-items">
							<button class="btn btn-icon btn-info py-0" (click)="editGroup(group, cancelGroupPrompt)"
								title="Edit Account Group" data-cy="EditGroupButton">
								<i class="far fa-pencil"></i>
							</button>
							<button class="btn btn-icon btn-remove py-0"
								(click)="deleteGroup(group, confirmDeletePrompt)" [disabled]="isDeleting.has(group.id)"
								title="Delete Account Group" data-cy="DeleteGroupButton">
								<i class="far fa-trash-alt"></i>
							</button>
							<button class="btn btn-icon btn-success py-0" (click)="addOrEditAccountGroupRule(group)"
								title="Add Rule" data-cy="AddRuleToAccountGroup">
								<i class="far fa-plus"></i>
							</button>
						</div>
					</div>
					<div ngbAccordionCollapse>
						<div ngbAccordionBody>
							<ng-template>
								<div class="p-3">
									<rl-form-table-row [labelText]="'Deal Template'">
										<ng-template rlFormTableControl>
											<rl-text-input [ngModel]="getDealTemplateDisplayText(group.dealTemplateId)"
												[displayAsText]="true"
												[dataLabel]="'dealTemplateLabel'"></rl-text-input>
										</ng-template>
									</rl-form-table-row>
									<rl-form-table-row [labelText]="'Party'">
										<ng-template rlFormTableControl>
											<rl-text-input
												[ngModel]="getPartyDisplayText(group.dealTemplateId, group.partyId)"
												[displayAsText]="true" [dataLabel]="'partyLabel'"></rl-text-input>
										</ng-template>
									</rl-form-table-row>
									<rl-form-table-row [labelText]="'Default Account'">
										<ng-template rlFormTableControl>
											<rl-text-input [ngModel]="getDefaultAccountName(group.defaultAccountId)"
												[displayAsText]="true"
												[dataLabel]="'defaultAccountLabel'"></rl-text-input>
										</ng-template>
									</rl-form-table-row>
									<div class="mt-3">
										<rl-form-table-group [groupName]="'Rules'">
											<rl-account-group-rules [accountGroup]="group"
												[defaultAccounts]="defaultAccounts"
												(maxSequenceNumber)="setMaxGroupSequenceNumber($event)"
												(edit)="editRule($event)"></rl-account-group-rules>
										</rl-form-table-group>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
				}
			</div>
			} @else {
			<div>
				<span>No Account Groups</span>
			</div>
			}
		</ng-template>
	</li>
</ul>
<div [ngbNavOutlet]="nav"></div>

<ng-template #cancelPrompt let-prompt="prompt">
	<div>
		<p *ngIf="isEditingExistingAccount">
			Are you sure you want to discard your changes for account <strong>{{ accountsForm.controls.accountId.value
				}}</strong>?
		</p>
		<p *ngIf="!isEditingExistingAccount">
			Are you sure you want to discard your changes?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="CancelButton">
			Cancel
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)"
			data-cy="DiscardConfirmButton">
			Discard My Changes
		</button>
	</div>
</ng-template>

<ng-template #cancelGroupPrompt let-prompt="prompt">
	<div>
		<p *ngIf="isEditingExistingAccountGroup">
			Are you sure you want to discard your changes for account group <strong>{{
				accountGroupsForm.controls.groupName.value
				}}</strong>?
		</p>
		<p *ngIf="!isEditingExistingAccountGroup">
			Are you sure you want to discard your changes?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="CancelButton">
			Cancel
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)"
			data-cy="DiscardConfirmButton">
			Discard My Changes
		</button>
	</div>
</ng-template>
<ng-template #confirmDeletePrompt let-prompt="prompt">
	<div>
		<p>
			{{ formType === 'account' ?
			'Are you sure you want to delete Account ' + prompt.context.name + '?' :
			'Are you sure you want to delete Account Group ' + (prompt.context.group?.groupName) + '?'
			}}
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="CancelButton">
			Cancel
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)" data-cy="DeleteConfirmButton">
			Delete
		</button>
	</div>
</ng-template>

<ng-template #confirmArchivePrompt let-prompt="prompt">
	<div>
		<p>
			Are you sure you want to archive account <b>{{ prompt.context.name }}</b>?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="CancelButton">
			Cancel
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)"
			data-cy="ArchiveConfirmButton">
			Archive Account
		</button>
	</div>
</ng-template>