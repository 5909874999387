<div class="acct-operation-event container-fluid mb-2 position-relative">
	<div class="row align-items-stretch">
		<div class="col-4 bg-light py-3">
			<h5 class="ps-3 mt-1">Workflow</h5>
			<em class="acct-operation-event__item">None</em>
			<div class="d-flex flex-column">
				<div class="acct-operation-event__item purple-arrow">
					<strong>Initial Status:</strong>
					{{ initialStatusLabel }}
				</div>
				<div class="acct-operation-event__item purple-arrow">
					<strong>Workflow Action:</strong>
					{{ wfActionLabel }}
				</div>
				<div class="acct-operation-event__item">
					<strong>Resulting Status</strong>
					{{ resultingStatusLabel }}
				</div>
			</div>
		</div>
		<div class="col-8 p-3">
			<div>
				<h5 class="mt-1">Revenue Allocation</h5>
				<div class="row acct-operation-event__accounts-row">
					<div class="col-5 d-flex flex-column acct-operation-event__item bg-white">
						<strong>Debit Account(s)</strong>
						<span class="text-muted" *ngIf="revCreditDebitAccountOrGroups?.length === 0">None</span>
						<span *ngFor="let accountOrGroup of revCreditDebitAccountOrGroups">
							@if (accountOrGroup.debitId.isGroup && accountOrGroup.allocationType === AllocationType.Revenue) {
							<div class="d-flex flex-column">
								<span>{{ accountOrGroup.debitName }}</span>
								<em>(Account Group)</em>
							</div>
							} @else {
							<span>{{ accountOrGroup.debitName }}</span>
							}
						</span>
					</div>
					<div class="col-5 d-flex flex-column acct-operation-event__item">
						<strong>Credit Account(s)</strong>
						<span class="text-muted" *ngIf="revCreditDebitAccountOrGroups?.length === 0">None</span>
						<span *ngFor="let accountOrGroup of revCreditDebitAccountOrGroups">
							@if (accountOrGroup.creditId.isGroup && accountOrGroup.allocationType === AllocationType.Revenue) {
							<div class="d-flex flex-column">
								<span>{{ accountOrGroup.creditName }}</span>
								<em>(Account Group)</em>
							</div>
							} @else {
							<span>{{ accountOrGroup.creditName }}</span>
							}
						</span>
					</div>
				</div>
			</div>
			@if (hasCostAllocation) {
			<div class="border-top pt-2 mt-1">
				<h5 class="mt-1">Cost Allocation</h5>
				<div class="row acct-operation-event__accounts-row">
					<div class="col-5 d-flex flex-column acct-operation-event__item bg-white">
						<strong>Debit Account(s)</strong>
						<span class="text-muted" *ngIf="costCreditDebitAccountOrGroups?.length === 0">None</span>
						<span *ngFor="let accountOrGroup of costCreditDebitAccountOrGroups">
							@if (accountOrGroup.debitId.isGroup && accountOrGroup.allocationType === AllocationType.Cost) {
							<div class="d-flex flex-column">
								<span>{{ accountOrGroup.debitName }}</span>
								<em>(Account Group)</em>
							</div>
							} @else {
							<span>{{ accountOrGroup.debitName }}</span>
							}
						</span>
					</div>
					<div class="col-5 d-flex flex-column acct-operation-event__item">
						<strong>Credit Account(s)</strong>
						<span class="text-muted" *ngIf="costCreditDebitAccountOrGroups?.length === 0">None</span>
						<span *ngFor="let accountOrGroup of costCreditDebitAccountOrGroups">
							@if (accountOrGroup.creditId.isGroup && accountOrGroup.allocationType === AllocationType.Cost) {
							<div class="d-flex flex-column">
								<span>{{ accountOrGroup.creditName }}</span>
								<em>(Account Group)</em>
							</div>
							} @else {
							<span>{{ accountOrGroup.creditName }}</span>
							}
						</span>
					</div>
				</div>
			</div>
			}
		</div>
	</div>
	<div class="position-absolute acct-operation-event__accounts-buttons align-items-center justify-content-end pe-2">
		<ng-content></ng-content>
	</div>
</div>